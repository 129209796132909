import React, { useEffect, useState } from 'react'
import '../styles/CustomerService/CustomerService.scss';
import HeaderLP3 from '../components/LP3/HeaderLP3';
import dotsSVG from '../assets/svgs/216.light.svg'
import customerServiceHero from '../assets/imgs/customer-service-hero.png'
import { useModal } from '../contexts/ModalContext';
import { Button, Card, Row, Col } from 'antd';
import Feature8LP3 from '../components/LP3/Feature8LP3';
import FooterLP3 from '../components/LP3/FooterLP3';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ScrollAnimation from 'react-animate-on-scroll';
import LogoSliderLP3 from '../components/LP3/LogoSliderLP3';
import gridSVG from '../assets/svgs/LP3-grid.svg'
import csAdvantage from '../assets/svgs/cs-advantage.svg'
import ic01 from '../assets/svgs/01-blue.svg';
import ic02 from '../assets/svgs/02-blue.svg';
import ic03 from '../assets/svgs/03-blue.svg';
import ic04 from '../assets/svgs/04-blue.svg';
import ic05 from '../assets/svgs/05-blue.svg';
import Feature7LP3 from '../components/LP3/Feature7LP3';
import F3L from '../assets/svgs/LP3F3Dots-left.svg';
import F3R from '../assets/svgs/LP3F3Dots-right.svg';
import arrowRightBlue from '../assets/svgs/arrow-blue.svg';
import arrowWhite from '../assets/svgs/arrow-white.svg';
import "animate.css/animate.compat.css"
import FaqClpsPanel from '../components/FaqClpsPanel';
import csAdvL from '../assets/svgs/scalable-teams-l.svg';
import csAdvR from '../assets/svgs/scalable-teams-r.svg';
const CustomerService = () => {
    const { showModal } = useModal();
    const csCardData = [
        {
            imgSrc: ic01,
            header: <><br />Exceptional Talent</>,
            subtext: 'Our in-house headhunting team sources top-tier customer service talent from around the globe, ensuring each agent undergoes thorough vetting, testing, and training to deliver the highest quality support.'
        },
        {
            imgSrc: ic02,
            header: 'Localisation Capabilities',
            subtext: 'Our agents are trained in omni-channel support (email, chat, voice). We offer accent neutralization technology for clear communication and trust, regardless of native language.'
        },
        {
            imgSrc: ic03,
            header: 'Cost-Effective Solutions',
            subtext: 'Hiring the best global talent and using cutting-edge AI, we offer exceptional customer service at affordable rates. Our 7-star support is accessible to companies of any size or budget.'
        },
        {
            imgSrc: ic04,
            header: 'Robust Security Measures',
            subtext: 'Protecting client and customer data is our priority, with agents signing strict NDAs and custom security policies tailored to each client. We are in the process of obtaining SOC 2 compliance for our software to ensure the highest standards.'
        },
        {
            imgSrc: ic05,
            header: 'Full-Service Management',
            subtext: 'Inflexion Teams will recruit, onboard, and train your customer support team with our cutting-edge AI technology and industry expertise. You won’t pay anything for us to completley train and onboard your entire team.'
        },
        {
            imgSrc: ic05,
            header: 'AI-Powered Advantage',
            subtext: 'Our proprietary AI technologies and partnerships with leading CX companies provide agents with real-time knowledge base access, intelligent routing, sentiment analysis, and continuous training, setting a new standard in customer service.'
        },

    ]
    const howCSOWorksCardData = [
        {
            imgSrc: ic01,
            header: 'Understand Your Customer Service Vision',
            subtext: 'We begin by diving deep into your customer experience goals. Our team collaborates with you to grasp your technical specifications, brand voice, and unique requirements for exceptional support.'
        },
        {
            imgSrc: ic02,
            header: <><br />Build and Upskill Your Dedicated Team</>,
            subtext: 'After comprehending your needs, we initiate our rigorous selection process. We\'ll recruit, extensively vet, and thoroughly train a team of top-tier support professionals tailored to your business.'
        },
        {
            imgSrc: ic03,
            header: <><br />Manage and Scale Your Productivity</>,
            subtext: 'We’ll continue to work closely with you to ensure your new team’s productivity, quality of work and output is running efficiently and scaling to your needs.'
        }
    ]

    const CSAdvantageCard = ({ imgSrc, header, subtext }) => {
        return (
            <Card className='card' style={{ border: 'none' }}>
                <div className='card-content'>
                    <img src={imgSrc} alt={header} className='card-image' />
                    <h2 className='card-header'>{header}</h2>
                    <p className='card-subtext'>{subtext}</p>
                </div>
            </Card>
        );
    };

    const agentSizeArray = [<span>Sales &nbsp;&nbsp;&nbsp; →</span>, <span>Technical Support &nbsp;&nbsp;&nbsp; →</span>, <span>Customer Service &nbsp;&nbsp;&nbsp; →</span>]
    const [selectedCell, setSelectedCell] = useState(null);

    const handleCellClick = (cellId) => {
        setSelectedCell(cellId);
    };
    const agentValCellStyle = (cellId) => ({
        borderRadius: '1.75rem',
        border: 'none',
        padding: '2.75rem 4.5rem',
        color: '#D6DCE0',
        background: '#1A1C20',
        cursor: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        transition: 'all 0.2s ease-in-out',
        fontSize: '1.5rem',
        fontFamily: 'Helvetica Now Display',
        fontWeight: '500',


    });


    const controlsFaqs = useAnimation();
    const { ref: refFaqs, inView: inViewFaqs } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    useEffect(() => {
        if (inViewFaqs) {
            controlsFaqs.start('visible');
        }
    }, [controlsFaqs, inViewFaqs]);

    const controlsHero = useAnimation();
    const { ref: refHero, inView: inViewHero } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    useEffect(() => {
        if (inViewHero) {
            controlsHero.start('visible');
        }
    }, [controlsHero, inViewHero]);

    const controlsFeature1 = useAnimation();
    const { ref: refFeature1, inView: inViewFeature1 } = useInView({
        triggerOnce: true,
        threshold: 0.01,
    });
    useEffect(() => {
        if (inViewFeature1) {
            controlsFeature1.start('visible');
        }
    }, [controlsFeature1, inViewFeature1]);

    const controlsFeature2 = useAnimation();
    const { ref: refFeature2, inView: inViewFeature2 } = useInView({
        triggerOnce: true,
        threshold: 0.01,
    });
    useEffect(() => {
        if (inViewFeature2) {
            controlsFeature2.start('visible');
        }
    }, [controlsFeature2, inViewFeature2]);

    const controlsFeature3 = useAnimation();
    const { ref: refFeature3, inView: inViewFeature3 } = useInView({
        triggerOnce: true,
        threshold: 0.01,
    });
    useEffect(() => {
        if (inViewFeature3) {
            controlsFeature3.start('visible');
        }
    }, [controlsFeature3, inViewFeature3]);




    const containerVariants = {
        hidden: { opacity: 0, y: 150 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                when: 'beforeChildren',
                staggerChildren: 0.3,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0 },
    };

    const itemVariants2 = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
    };


    useEffect(() => {
        const handleScroll = () => {
            const footer = document.querySelector('.footer');
            const scrollPosition = window.scrollY + window.innerHeight;
            const threshold = document.documentElement.scrollHeight * 0.85; // 85% scroll

            if (scrollPosition >= threshold) {
                footer.classList.add('show');
            } else {
                footer.classList.remove('show');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <section className='customer-service-section'>
            <HeaderLP3 />


            {/* <section className='customer-service-hero-section'>
                <img src={dotsSVG} style={{ position: 'absolute', top: '-12rem', zIndex: '1', maxWidth: '100%', height: 'auto', left: '0' }} />
                <div className='customer-service-hero'>
                    <img src={customerServiceHero} style={{ margin: '0 auto', maxWidth: '100%', height: 'auto' }} />
                    <div className='header-container'>
                        <p className='pre-header'>
                            Solutions  `{'>'}`  Customer Service
                        </p>
                        <div className='header'>
                            Exceptional Customer Service, Simplified
                        </div>
                        <p className='subtext'>
                            Elevate your customer experience with Inflexion Teams. Our done-for-you solution provides top-tier, AI-enhanced customer service agents to support your global customer base. We make delivering 7-star customer service simple and affordable.
                        </p>
                        <Button
                            className='get-started-cta'
                            onClick={() => { showModal(); }}
                        >
                            Book Your Free Trial
                        </Button>
                    </div>

                </div>
                <>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <LogoSliderLP3 />
                    </ScrollAnimation>
                    <div className='how-it-works-gridImg-container'>
                        <img src={gridSVG} />
                    </div>
                </>
            </section> */}

            <section
                className='customer-service-hero-section'

            >
                <img src={dotsSVG} style={{ position: 'absolute', top: '-12rem', zIndex: '1', maxWidth: '100%', height: 'auto', left: '0' }} />
                {/* <motion.div className='customer-service-hero'
                    ref={refHero}
                    initial="hidden"
                    animate={controlsHero}
                    variants={containerVariants}>
                    <img src={customerServiceHero} style={{ margin: '0 auto', maxWidth: '100%', height: 'auto' }} />
                    <div className='header-container'>
                        <p className='pre-header'>
                            {'Solutions  >  Customer Service'}
                        </p>
                        <div className='header'>
                            Exceptional Customer Service, Simplified
                        </div>
                        <p className='subtext'>
                            Elevate your customer experience with Inflexion Teams. Our done-for-you solution provides top-tier, AI-enhanced customer service agents to support your global customer base. We make delivering 7-star customer service simple and affordable.
                        </p>
                        <Button
                            className='get-started-cta'
                            onClick={() => { showModal(); }}
                        >
                            Book Your Free Trial
                        </Button>
                    </div>

                </motion.div> */}
                <motion.div
                    className="customer-service-hero"
                    ref={refHero}
                    initial="hidden"
                    animate={controlsHero}
                    variants={containerVariants}
                >
                    <motion.div className="background-img" variants={itemVariants} />
                    <motion.div className="header-container" variants={itemVariants}>
                        <p className="pre-header">{'Solutions  >  Customer Service'}</p>
                        <div className="header">Exceptional Customer Service, Simplified</div>
                        <p className="subtext">
                            Elevate your customer experience with Inflexion Teams. Our done-for-you solution provides top-tier, AI-enhanced customer service agents to support your global customer base. We make delivering 7-star customer service simple and affordable.
                        </p>
                        <Button className="get-started-cta" onClick={() => showModal()}>
                            Book Your Free Trial
                        </Button>
                    </motion.div>
                </motion.div>

                <>
                    <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                        <h1 className='carousel-header'>We’ve hired talent from</h1>
                        <LogoSliderLP3 />
                    </ScrollAnimation>
                    <div className='how-it-works-gridImg-container'>
                        <img src={gridSVG} />
                    </div>
                </>
            </section>

            <section className='cs-advantage-section'>
                <motion.div className='cs-advantage'
                    ref={refFeature1}
                    initial="hidden"
                    animate={controlsFeature1}
                    variants={containerVariants}>
                    <motion.div className='header-container'>
                        <img className='img-l' src={csAdvL} style={{ maxWidth: '100%', height: 'auto' }} />
                        <img className='img-r' src={csAdvR} style={{ maxWidth: '100%', height: 'auto' }} />
                        <span className='pre-header'>
                            BENEFITS
                        </span>
                        <div className='header'>
                            The Inflexion Teams Advantage
                        </div>
                        <div className='subtext'>
                            With Inflexion Teams, you gain an end-to-end customer service partner. We recruit, vet, train and manage the absolute best customer support talent, then integrate cutting-edge AI to amplify their capabilities. The result is a turnkey, cost-effective solution to make exceptional customer service your competitive advantage.
                        </div>
                    </motion.div>
                    <motion.img src={csAdvantage} style={{ maxWidth: '100%', height: 'auto' }} variants={containerVariants} />
                    <motion.div className='cs-advantage-cards-container' variants={containerVariants}>
                        {csCardData.map((card, index) => (
                            <CSAdvantageCard
                                key={index}
                                imgSrc={card.imgSrc}
                                header={card.header}
                                subtext={card.subtext}
                            />
                        ))}
                    </motion.div>
                </motion.div>
            </section>

            <section className='how-cso-works-section'>
                <div className='dots-svg-container'>
                    <img className='f3l' src={F3L} style={{ maxWidth: '100%', height: 'auto' }} />
                    <img className='f3r' src={F3R} style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
                <motion.div className='how-cso-works'
                    ref={refFeature2}
                    initial="hidden"
                    animate={controlsFeature2}
                    variants={containerVariants}>
                    <motion.div className='header' variants={itemVariants}>
                        How Our Customer Service Outsourcing Works
                    </motion.div>

                    <motion.div className='how-cso-works-cards-container' variants={itemVariants}>
                        {howCSOWorksCardData.map((card, index) => (
                            <CSAdvantageCard
                                key={index}
                                imgSrc={card.imgSrc}
                                header={card.header}
                                subtext={card.subtext}
                            />
                        ))}
                    </motion.div>

                </motion.div>
            </section>

            <section className='faqs-section'>
                <motion.div
                    ref={refFaqs}
                    initial="hidden"
                    animate={controlsFaqs}
                    variants={containerVariants}
                    className='faqs-container'
                >
                    <div className='header-container'>
                        <span className='pre-header'>
                            FAQ’s
                        </span>
                        <h1 className='header'>
                            Frequently Asked questions
                        </h1>
                    </div>
                    <motion.div className='faqs-collapse-container'>
                        <FaqClpsPanel data={'data2'} />
                    </motion.div>
                </motion.div>
            </section>

            <section className='scale-teams-section'>
                <motion.div className='scale-teams'
                    ref={refFeature3}
                    initial="hidden"
                    animate={controlsFeature3}
                    variants={containerVariants}>
                    <motion.div className='header-container' variants={itemVariants}>
                        <div className='header'>
                            Scale Your <span style={{ color: '#2A71FA' }}>Contact Center Team</span>
                        </div>
                        <span className='subtext'>
                            Hire an end-to-end CX team in weeks (not months).
                        </span>
                    </motion.div>

                    <motion.div className='agent-val-grid' variants={itemVariants}>
                        <Row gutter={[16, 16]}>
                            {agentSizeArray.map((agentSize, index) => (
                                <Col key={index}
                                    xs={24}
                                    sm={24}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                >
                                    <div style={agentValCellStyle(index)} className='agent-val-cell' variants={itemVariants2}>
                                        {agentSize}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </motion.div>

                    <motion.div className='schedule-call-container' variants={containerVariants}>
                        <span className='schedule-text'>
                            We help companies scale with a global workforce.
                        </span>

                        <Button className='schedule-call-cta' onClick={() => window.open('https://calendly.com/james-inflexion/inflexion-teams-call', '_blank')}>
                            Schedule a consultation call <img src={arrowWhite} className='arrow-icon-hover' /><img src={arrowRightBlue} className='arrow-icon' />
                        </Button>
                    </motion.div>
                </motion.div>
            </section>


            <Feature7LP3 />
            <div className='footer'>
                <Feature8LP3 />
                <FooterLP3 />
            </div>
        </section>
    )
}

export default CustomerService



