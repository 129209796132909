import React, { useState, useRef } from 'react'
import dotsLP3F8 from '../../assets/svgs/dots-LP3F8.svg'
import arrowSVG from '../../assets/svgs/arrow-circleBG.svg';
const Feature8LP3 = () => {
    const emailRef = useRef(null);
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        // Simple email regex for validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form behavior

        const email = emailRef.current.value;

        if (validateEmail(email)) {
            setError(''); // Clear any existing error
            console.log('Email submitted', email);
            window.alert('Email submitted!');
        } else {
            setError('Please enter a valid email address.');
        }
    };

    return (
        <section className='feature-8-LP3-section'>
            <img className='dots-svg' src={dotsLP3F8} />
            <div className='feature-8-LP3'>
                <h1 className='header'>
                    Learn how to save up to 70% on your CX.<br />
                    <span style={{ fontWeight: '700' }}>Download our free guide now.</span>
                </h1>

                <div className="input-container">
                    <input type="email" required ref={emailRef} placeholder="your@email.com" className="custom-input" />
                    <img src={arrowSVG} alt="Arrow" onClick={handleSubmit} className="input-arrow" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
                {error && <p className="error-text" style={{ color: 'red', margin: '0', marginTop: '-0.5rem' }}>{error}</p>}
            </div>
        </section>
    )
}

export default Feature8LP3